<div id="sidebar"
     class="position-fixed">
    <div class="container">
        <div class="sidebar-header">
            <h3>
                <img id="brillpower-logo"
                     [src]="logo"
                     alt="">
            </h3>
        </div>
        <div class="mobile-sidebar-header">
            <h3>
                <img id="brillpower-logo"
                     [src]="logo"
                     alt="">
            </h3>
        </div>
        <div class="menu-space">
            <ul class="pt-3">
                <!-- Dashboard -->
                <li class="transform">
                    <a routerLink="dashboard"
                       [ngClass]="{'is-active': isRouteActive('/dashboard?organisation')}">
                        <i class="pi pi-fw pi-th-large me-4"></i>
                        <span>{{'dashboard.title' | translate}}</span>
                    </a>

                    <ul
                        *ngIf="isRouteActive('/dashboard/system') || isRouteActive('/battery') || isRouteActive('/module')">
                        <li>
                            <a routerLink="/dashboard/system"
                               class="sub-item"
                               routerLinkActive="is-active">
                                <i class="pi pi-fw pi-chart-line me-4"></i>
                                <span>{{'sidebar.system.dashboard' | translate}}</span>
                            </a>
                        </li>
                    </ul>

                    <ul *ngIf="isRouteActive('/battery')  || isRouteActive('/module')">
                        <li>
                            <a routerLink="battery"
                               class="sub-item-1"
                               routerLinkActive="is-active">
                                <img class="me-4"
                                     src="./../../../../assets/images/icons/white-battery.svg"
                                     alt="">
                                <span>{{'sidebar.battery.dashboard' | translate}}</span>
                            </a>
                        </li>
                    </ul>

                    <ul *ngIf="isRouteActive('/module')">
                        <li>
                            <a routerLink="module"
                               class="sub-item-2"
                               routerLinkActive="is-active">
                                <i class="pi pi-fw pi-box me-4"></i>
                                <span>{{'sidebar.module' | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- Data explorer -->
                <li class="transform"
                    *ngIf="(PermissionType.dataexplorer | permissionCheck) | async">
                    <a routerLink="telemetry"
                       routerLinkActive="is-active">
                        <i class="pi pi-fw pi-chart-bar me-4"></i>
                        <span>{{'sidebar.dataexplorer' | translate}}</span>
                    </a>
                </li>
                <!-- Cell Degradation -->
                <li class="transform"
                    *ngIf="(PermissionType.capacityprediction| permissionCheck) | async">
                    <a routerLink="degradation"
                       routerLinkActive="is-active">
                        <i class="pi pi-fw pi-sort-amount-down me-4"></i>
                        <span>{{'sidebar.degradation' | translate}}</span>
                    </a>
                </li>
                <!-- Admin access -->
                <li class="transform"
                    *ngIf="(PermissionType.admin | permissionCheck) | async">
                    <a (click)="explandSidebarPanel()"
                       class="collapsible">
                        <i class="pi pi-fw pi-users me-4"></i>
                        <span>{{'sidebar.administration' | translate}}</span>
                        <i class="pi pi-fw ms-4 up-down-icon"
                           [ngClass]="isCollapsed ? 'pi-angle-up' : 'pi-angle-down'"></i>
                    </a>

                    <ul *ngIf="isCollapsed"
                        class="pb-0">
                        <li *ngIf="(PermissionType.admin | permissionCheck) | async">
                            <a routerLink="admin"
                               class="sub-item"
                               routerLinkActive="is-active">
                                <i class="pi pi-fw pi-user me-4"></i>
                                <span>{{'sidebar.users' | translate}}</span>
                            </a>
                        </li>
                    </ul>

                    <ul *ngIf="isCollapsed">
                        <li
                            *ngIf="((PermissionType.firmwareupdate | permissionCheck) | async) || ((PermissionType.parametermanagement | permissionCheck) | async)">
                            <a routerLink="devicemanagement"
                               class="sub-item"
                               routerLinkActive="is-active">
                                <i class="pi pi-fw pi-wrench me-4"></i>
                                <span>{{'sidebar.devicemanagement' | translate}}</span>
                            </a>
                        </li>
                    </ul>

                    <ul *ngIf="isCollapsed"
                        class="pt-0">
                        <li *ngIf="((PermissionType.notifications | permissionCheck) | async)">
                            <a routerLink="notificationconfiguration"
                               class="sub-item"
                               routerLinkActive="is-active">
                                <i class="pi pi-fw pi-bell me-4"></i>
                                <span>{{'sidebar.notificationmanagement' | translate}}</span>
                            </a>
                        </li>
                    </ul>

                </li>
            </ul>
        </div>
    </div>
</div>