<p-dialog [(visible)]="isVisible"
          [closable]="false"
          [style]="{ width: width }"
          [modal]="true">

    <ng-template pTemplate="header">
        <span class="headertext">
            <i class="pi {{modalConfig?.iconType}} error"></i>{{modalConfig?.modalTitle |
            translate:modalConfig?.translateParams}}</span>
    </ng-template>

    <ng-template pTemplate="content">
        <ng-container *ngIf="modalConfig">
            <ng-content></ng-content>
        </ng-container>
    </ng-template>

    <ng-template pTemplate="footer">
        @if(!modalConfig.hideCancelButton) {

        <p-button icon="pi pi-times"
                  styleClass="cancel-btn"
                  (onClick)="onCancel()"
                  [disabled]="modalConfig.disableCancelButton"
                  label="{{modalConfig.onCancelButtonLabel || 'Close' | translate}}">
        </p-button>
        }
        @if(!modalConfig.hideSubmitButton) {

        <p-button (click)="onSubmit()"
                  icon="pi pi-check-circle"
                  [loading]="modalConfig?.isLoading"
                  label="{{modalConfig.onSubmitButtonLabel || 'Dismiss' | translate}}"
                  [disabled]="modalConfig.disableSubmitButton">

        </p-button>

        }

        @if(modalConfig?.errorMessage) {
        <div class="error-message-container pt-1"
             id="error-message-text">
            <span>{{modalConfig?.errorMessage}}</span>
        </div>
        }
    </ng-template>

</p-dialog>