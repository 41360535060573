import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private currentTheme = 'light'; // Default theme


    constructor(@Inject(DOCUMENT) private document: Document) { }

    setTheme(theme: string) {
        const element = document.querySelector('html');
        this.currentTheme = theme;
        const logoElement = document.querySelectorAll('#brillpower-logo');
        logoElement.forEach(x => {
            if (logoElement) theme === 'dark' ? x.setAttribute('src', 'assets/images/logo/brill-analytics-dark-logo.svg') : x.setAttribute('src', 'assets/images/logo/brill-analytics-white-logo.svg');
        })
        if (this.currentTheme === 'dark') {
            element.classList.add('dark-mode');
            // element.classList.remove('light-mode');
        } else {
            element.classList.remove('dark-mode');
            // element.classList.add('dark-mode');
        }

        localStorage.setItem('Preferred-theme', theme);
    }
}
