import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import * as signalR from '@microsoft/signalr';
import { firstValueFrom, Observable } from 'rxjs';
import { GlobalUrl } from 'src/app/global-url';
import { environment } from 'src/environments/environment';
import { BatterySummary } from '../../modules/data-explorer/entities/api/batterysummary';
import { ModuleSummary } from '../../modules/data-explorer/entities/api/modulesummary';
import { Summary } from '../../modules/data-explorer/entities/api/summary';
import { GatewaySummary } from '../../modules/data-explorer/entities/api/gatewaysummary';
import { OrganisationSummary } from '../../modules/data-explorer/entities/api/organisationsummary';
import { SupercellSummary } from '../../modules/data-explorer/entities/api/supercellsummary';
import { Notification } from 'src/app/shared/entities/notification';
import { LogMessage } from '../../modules/device-management/entities/api/logmessage';

export class SignalRService<TMessage extends Summary> {
    private hubConnection: signalR.HubConnection;

    constructor(private authService: MsalService,
        private url: GlobalUrl,
        private urlFragment: string,
        private eventName: string) {
    }

    start(url = this.url.baseUrl): Promise<void> {
        const silentRequest: SilentRequest = {
            scopes: [environment.apiScope],
            account: this.authService.instance.getAllAccounts()[0]
        };

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url + "hubs" + this.urlFragment, {
                accessTokenFactory: () =>
                    firstValueFrom(this.authService.acquireTokenSilent(silentRequest)).then((authResult) => {
                        return authResult.accessToken
                    })
            })
            .withAutomaticReconnect()
            .build();

        return this.hubConnection.start()
            .then(() => console.log(`Connected to hub ${this.urlFragment}`));
    }

    stop(): Promise<void> {
        return this.hubConnection.stop();
    }

    subscribe(ids: any[]): Promise<void> {
        console.log("Subscribing for " + ids.join());
        return this.hubConnection.send("Subscribe", ids);
    }

    unsubscribe(ids: any[]): Promise<void> {
        console.log("Unsubscribing from " + ids.join());
        return this.hubConnection.send("Unsubscribe", ids);
    }

    addListener(): Observable<TMessage> {
        return new Observable((observer) => {
            if (this.hubConnection.state == signalR.HubConnectionState.Connected) {
                this.hubConnection.on(this.eventName, (data: TMessage) => {
                    if (data) {
                        data.lastUpdated = new Date(data.lastUpdated); // fix banterous JavaScript: dates returned from JSON are not actually dates :melting-face:
                        observer.next(data);
                    }
                });
            } else {
                observer.error("SignalR connection not established");
            }
        });
    }
}

@Injectable({
    providedIn: 'root'
})
export class OrganisationSummarySignalRService extends SignalRService<OrganisationSummary> {
    constructor(authService: MsalService,
        url: GlobalUrl) {
        super(authService, url, "/summary/organisation", "OnOrganisationSummaryChanged");
    }
}

@Injectable({
    providedIn: 'root'
})
export class GatewaySummarySignalRService extends SignalRService<GatewaySummary> {
    constructor(authService: MsalService,
        url: GlobalUrl) {
        super(authService, url, "/summary/gateway", "OnGatewaySummaryChanged");
    }
}

@Injectable({
    providedIn: 'root'
})
export class BatterySummarySignalRService extends SignalRService<BatterySummary> {
    constructor(authService: MsalService,
        url: GlobalUrl) {
        super(authService, url, "/summary/battery", "OnBatterySummaryChanged");
    }
}

@Injectable({
    providedIn: 'root'
})
export class ModuleSummarySignalRService extends SignalRService<ModuleSummary> {
    constructor(authService: MsalService,
        url: GlobalUrl) {
        super(authService, url, "/summary/module", "OnModuleSummaryChanged");
    }
}
@Injectable({
    providedIn: 'root'
})
export class SupercellSummarySignalRService extends SignalRService<SupercellSummary> {
    constructor(authService: MsalService,
        url: GlobalUrl) {
        super(authService, url, "/summary/supercell", "OnSupercellSummaryChanged");
    }
}

@Injectable({
    providedIn: 'root'
})
export class LogStreamingSignalRService extends SignalRService<LogMessage> {
    constructor(authService: MsalService,
        url: GlobalUrl) {
        super(authService, url, "/console", "OnConsoleLogMessage");
    }
}

@Injectable({
    providedIn: 'root'
})
export class NotificationSignalRService extends SignalRService<Notification> {
    constructor(authService: MsalService,
        url: GlobalUrl) {
        super(authService, url, "/notification", "SendMessage");
    }
}