import { Injectable } from '@angular/core';
import { GlobalUrl } from '../global-url';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserAuthorityService {
    permissions: string[] = [];
    allPermissions = new Map<string, boolean>();
    permissionsInitialization: Promise<void>;
    constructor(private http: HttpClient, private url: GlobalUrl) { }

    private get<T>(partialUrl: string): Promise<T> {
        return firstValueFrom(this.http.get<T>(this.url.baseUrl + partialUrl));
    }

    async setPermissions(): Promise<void> {
        this.permissionsInitialization = this.get<string[]>(`auth`)
            .then((permissions) => {
                this.allPermissions = new Map<string, boolean>(
                    permissions.map(permission => [permission, true])
                );
            })
            .catch((error) => {
                console.error('Failed to fetch permissions:', error);
            });
    }


    async hasPermission(permission: string): Promise<boolean> {
        await this.permissionsInitialization;
        return this.allPermissions.has(permission);
    }

    async getPermissions(): Promise<Map<string, boolean>> {
        await this.permissionsInitialization;
        return this.allPermissions;
    }
}
