import { Component, Input } from '@angular/core';
import { NotificationEventGroup } from './api/notificationeventgroup';

@Component({
    selector: 'notification-events',
    templateUrl: './notification-events.component.html',
    styleUrl: './notification-events.component.scss'
})
export class NotificationEventsComponent {
    @Input() isNotificationEventDataLoading = true;
    @Input() notificationEventLoadingStatus: string = 'Loading...';
    @Input() notificationEventsData: NotificationEventGroup[] = [];
    @Input() maxHeight: string = '';
}
