import { Pipe, PipeTransform } from '@angular/core';
import { UserAuthorityService } from 'src/app/security/user-authority.service';

@Pipe({
    name: 'permissionCheck'
})
export class PermissionCheckPipe implements PipeTransform {
    constructor(private userAuthorityService: UserAuthorityService) { }

    async transform(permission: string): Promise<boolean> {
        const check = await this.userAuthorityService.hasPermission(permission);
        return check
    }
}
