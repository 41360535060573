<div class="card p-2 notification-card"
     [style.maxHeight]="maxHeight">
    <h3 class="notification-header">
        Notifications
    </h3>
    @for (groupedNotification of notificationEventsData ; track $index) {
    <div class="notification-title">{{groupedNotification.name}}
        @for (notification of groupedNotification.data; track $index) {
        <a target="_blank"
           *ngIf="notification.action; else noLink"
           href="{{notification.action}}">
            <div class="notification-messages">
                <div class="notification-title">
                    {{notification.title}}
                </div>
                <div class="notification-message">
                    {{ notification.content }}
                    <span id="notification-timestamp">
                        {{notification.timestamp | dateAgo}}
                    </span>
                </div>
            </div>
        </a>

        <ng-template #noLink>
            <div class="notification-messages"
                 style="cursor: not-allowed;">
                <div class="notification-title">
                    {{notification.title}}
                </div>
                <div class="notification-message">
                    {{ notification.content }}
                    <span id="notification-timestamp">
                        {{notification.timestamp | dateAgo}}
                    </span>
                </div>
            </div>
        </ng-template>
        }
    </div>
    }

    @if (isNotificationEventDataLoading) {
    <p class="loadingState">{{notificationEventLoadingStatus}}
    </p>
    }

</div>