<div class="container-fluid"
     [hidden]="!incomingLatAndLong">
    <div class="row px-3">
        <div class="col-12 card panel p-0">
            <div class="card-body p-0">
                <div id="map-container">
                </div>
            </div>
        </div>
    </div>

</div>